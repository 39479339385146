import {firstValueFrom, Observable} from "rxjs";
import {StorageService} from "@lib/services/storage.service";
import {Provider, Type} from "@angular/core";
import {parseJwt} from "@juulsgaard/ts-tools";

export abstract class RefreshTokenAuthService {

  private static WEEK = 7 * 24 * 60 * 60;

  static Provide(service: Type<RefreshTokenAuthService>): Provider[] {
    return [service, {provide: RefreshTokenAuthService, useExisting: service}];
  }

  abstract readonly currentTokenData$: Observable<RefreshTokenData>;
  abstract readonly tokenData$: Observable<RefreshTokenData | undefined>;

  abstract readonly currentRefreshToken$: Observable<string>;
  abstract readonly currentUserId$: Observable<string>;

  abstract readonly standalone: boolean;

  async getRefreshToken() {
    return await firstValueFrom(this.currentRefreshToken$);
  }

  async getTokenData() {
    return await firstValueFrom(this.currentTokenData$);
  }

  abstract invalidateToken(): void;

  //<editor-fold desc="Read Tokens from Disk">
  protected readToken(storage: StorageService, key: string) {
    if (!storage.isAvailable()) return undefined;

    const str = storage.read(key)
    if (!str) return undefined;

    const json = JSON.parse(str) as Partial<RefreshTokenData> | undefined;

    if (!this.validateToken(json)) {
      storage.delete(key);
      return undefined;
    }

    const now = new Date().getTime() / 1000;

    // If it expires the next week, discard
    if (now + RefreshTokenAuthService.WEEK > json.exp) {
      storage.delete(key);
      return undefined;
    }

    return json;
  }

  protected formatToken(token: RefreshTokenData) {
    return JSON.stringify(token);
  }

  protected parseToken(token: string): RefreshTokenData|undefined {

    const tokenData = parseJwt<{ sub?: string, exp?: number, scope?: string, 's-id'?: string }>(token);
    if (!tokenData.sub) return undefined;
    if (!tokenData.exp) return undefined;

    return {identityId: tokenData.sub, userId: tokenData.sub, token, exp: tokenData.exp, scope: tokenData.scope, resourceId: tokenData["s-id"]};
  }

  protected validateToken(token: Partial<RefreshTokenData> | undefined): token is RefreshTokenData {
    return !!token && !!token.userId && !!token.identityId && !!token.token && !!token.exp;
  }

  //</editor-fold>

  abstract logOut(): void;
}

export interface RefreshTokenData {
  userId: string;
  identityId: string;
  token: string;
  scope?: string;
  resourceId?: string;
  exp: number;
}
